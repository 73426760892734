import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Хянах самбараас захиалга үүсгэх",
  "description": null,
  "author": "OTC help",
  "category": "dashboard",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 3
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Хэрхэн хянах самбараас захиалга үүсгэх вэ?`}</h2>
    <p>{`Хянах самбараас зөвхөн хоёрдогч зах зээлийн арилжааны захиалга үүсгэх боломжтой.`}</p>
    <ol>
      <li parentName="ol">{`Сонгосон үнэт цаасны баруун булан дахь `}<strong parentName="li">{`[Create RFQ]`}</strong>{` товчийг дарж захиалга үүсгэх хэсэгт очно.`}</li>
      <li parentName="ol">{`Захиалга өгөх хэсэгт дараах мэдээллүүдийг оруулна:`}
        <ul parentName="li">
          <li parentName="ul">{`[Security]`}{` - хэсэгт захиалга өгөх үнэт цаасыг жагсаалтаас сонгоно`}</li>
          <li parentName="ul">{`[Visibility]`}{` - хэсэгт нийтэд нээлттэй `}{`[Public]`}{` эсвэл тухайлсан оролцогч болон оролцогчдод тусгайлан `}{`[Private]`}{` захиалга үүсгэх гэсэн сонголтыг хийнэ`}</li>
          <li parentName="ul">{`[Direct offer to]`}{` - `}{`[Visibility]`}{` хэсэгт `}{`[Private]`}{` сонголт хийсэн тохиолдолд тусгайлан захиалга илгээх шууд оролцогчийг жагсаалтаас сонгоно, нэгээс олон оролцогч сонгох боломжтой`}</li>
          <li parentName="ul">{`[Side]`}{` - хэсэгт `}{`[Buy]`}{` авах эсвэл `}{`[Sell]`}{` зарах гэсэн сонголтыг хийнэ`}</li>
          <li parentName="ul">{`[Minimum quantity]`}{` - хэсэгт үнэт цаасны захиалгын доод хэмжээ буюу захиалах хамгийн бага тоо ширхгийг оруулна`}</li>
          <li parentName="ul">{`[Maximum quantity]`}{` - хэсэгт үнэт цаасны захиалгын дээд хэмжээ буюу захиалах хамгийн их тоо ширхгийг оруулна`}</li>
          <li parentName="ul">{`[Price]`}{` - хэсэгт захиалга өгөх нэгж үнийн дүнг оруулна`}</li>
          <li parentName="ul">{`[End date]`}{` - хэсэгт захиалгын хүчинтэй хугацаа дуусах огноог сонгон оруулна. Захиалгын хүчинтэй хугацаанд захиалга биелээгүй тохиолдолд тухайн захиалга самбар дээрээс устах болно.`}</li>
        </ul>
      </li>
    </ol>
    <p>{`Дээрх захиалгын мэдээллүүдийг оруулан `}<strong parentName="p">{`[Create]`}</strong>{` товчийг дарж захиалгыг амжилттай үүсгэнэ.
Оруулсан захиалгын мэдээллийг бүхэлд нь арилган шинээр оруулах бол `}<strong parentName="p">{`[Reset]`}</strong>{` товчийг дарж арилгана.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`[Public]`}{` тохиргоотой илгээсэн захиалгыг платформын нийт оролцогчид `}{`[Secondary]`}{` дэд цэсний `}{`[Buy offers]`}{`, `}{`[Sell offers]`}{` хэсгээс харах боломжтой`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`[Private]`}{` тохиргоотой илгээсэн захиалгыг тусгайлан сонгосон оролцогчид л харах боломжтой бөгөөд захиалгын урд хэсэгт түгжээ бүхий тэмдэг байна.`}</p>
      </li>
    </ul>
    <p>{`Үүсгэсэн захиалгыг засварлах бол тухайн захиалгын хойд хэсэг дэх `}<strong parentName="p">{`[Actions]`}</strong>{` товчийг дарж `}<strong parentName="p">{`[Update]`}</strong>{` хэсгийг сонгон мэдээллийг өөрчилнө. Захиалгыг хүчинтэй хугацаа дуусахаас өмнө цуцлах бол `}<strong parentName="p">{`[Close]`}</strong>{` товчийг дарна.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      